export enum validationMessages {
  INVALID_UUID_V4 = 'INVALID_UUID_V4',
  VALIDATION_ERROR = 'validations:client.VALIDATION_ERROR',
  FIELD_REQUIRED = 'validations:client.FIELD_REQUIRED',
  MAX_LENGTH = 'validations:client.MAX_LENGTH',
  MIN_LENGTH = 'validations:client.MIN_LENGTH',
  MIN_DURATION = 'validations:client.MIN_DURATION',
  MAX_DURATION = 'validations:client.MAX_DURATION',
  MAX_DURATION_60 = 'validations:client.MAX_DURATION_60',
  DURATION_REQUIRED = 'validations:client.DURATION_REQUIRED',
  SCHEDULING_SHIFT_BREAK_TYPE_HAS_INVALID_TIME_PERIOD = 'validations:client.SCHEDULING_SHIFT_BREAK_TYPE_HAS_INVALID_TIME_PERIOD',
  MIN_NUMBER = 'validations:client.MIN_NUMBER',
  MAX_NUMBER = 'validations:client.MAX_NUMBER',
  INVALID_DATE = 'validations:client.INVALID_DATE',
  INVALID_FORMAT = 'validations:client.INVALID_FORMAT',
  INVALID_VALUE = 'validations:client.INVALID_VALUE',
  INVALID_EMAIL = 'validations:client.INVALID_EMAIL',
  INVALID_INTEGER = 'validations:client.INVALID_INTEGER',
  INVALID_BOOLEAN = 'validations:client.INVALID_BOOLEAN',
  FIELD_EXISTS = 'validations:client.FIELD_EXISTS',
  INVALID_ARRAY = 'validations:client.INVALID_ARRAY',
  IS_BEFORE = 'validations:client.IS_BEFORE',
  IS_AFTER = 'validations:client.IS_AFTER',
  ARRAY_MIN_LENGTH = 'validations:client.ARRAY_MIN_LENGTH',
  ARRAY_MAX_LENGTH = 'validations:client.ARRAY_MAX_LENGTH',
  STATUS_IS_INVALID = 'validations:client.STATUS_IS_INVALID',
  INVALID_ALPHA = 'validations:client.INVALID_ALPHA',
  INVALID_ALPHA_WITH_SPECIAL_SYMBOLS = 'validations:client.INVALID_ALPHA_WITH_SPECIAL_SYMBOLS',
  INVALID_ALPHANUMERIC_WITH_SPECIAL_SYMBOLS = 'validations:client.INVALID_ALPHANUMERIC_WITH_SPECIAL_SYMBOLS',
  FIRST_CHAR_IS_SPACE = 'validations:client.FIRST_CHAR_IS_SPACE',
  ONLY_CONTAINS_SPECIAL_CHARS = 'validations:client.ONLY_CONTAINS_SPECIAL_CHARS',
  INVALID_POSITIVE_NUMBER = 'validations:client.INVALID_POSITIVE_NUMBER',
  INVALID_MAX_FILE_SIZE = 'validations:client.INVALID_MAX_FILE_SIZE',
  INVALID_MIME_TYPE = 'validations:client.INVALID_MIME_TYPE',
  INVALID_PHONE_CODE = 'validations:client.INVALID_PHONE_CODE',
  INVALID_PHONE_NUMBER = 'validations:client.INVALID_PHONE_NUMBER',
  IDENTITY_CODE_IS_INVALID = 'validations:client.IDENTITY_CODE_IS_INVALID',
  IDENTITY_COGNITO_CODE_IS_INVALID = 'validations:client.IDENTITY_COGNITO_CODE_IS_INVALID',
  IDENTITY_NAME_IS_INVALID = 'validations:client.IDENTITY_NAME_IS_INVALID',
  IDENTITY_PASSWORD_IS_INVALID = 'validations:client.IDENTITY_PASSWORD_IS_INVALID',
  IDENTITY_LOCALE_IS_INVALID = 'validations:client.IDENTITY_LOCALE_IS_INVALID',
  ORGANIZATION_USER_ROLE_IS_INVALID = 'validations:client.ORGANIZATION_USER_ROLE_IS_INVALID',
  ORGANIZATION_COMPANY_SIZE_IS_INVALID = 'validations:client.ORGANIZATION_COMPANY_SIZE_IS_INVALID',
  ORGANIZATION_INDUSTRY_IS_INVALID = 'validations:client.ORGANIZATION_INDUSTRY_IS_INVALID',
  ORGANIZATION_GOALS_ARE_INVALID = 'validations:client.ORGANIZATION_GOALS_ARE_INVALID',
  ORGANIZATION_TIME_BALANCE_TYPE_IS_INVALID = 'validations:client.ORGANIZATION_TIME_BALANCE_TYPE_IS_INVALID',
  ORGANIZATION_TIME_BALANCE_INTERVAL_IS_INVALID = 'validations:client.ORGANIZATION_TIME_BALANCE_INTERVAL_IS_INVALID',
  ORGANIZATION_ALL_DAY_DURATION_IS_INVALID = 'validations:client.ORGANIZATION_ALL_DAY_DURATION_IS_INVALID',
  ORGANIZATION_TIME_BALANCE_IMPACT_TIME_IS_INVALID = 'validations:client.ORGANIZATION_TIME_BALANCE_IMPACT_TIME_IS_INVALID',
  ORGANIZATION_TIME_BALANCE_TRACKING_UNIT_IS_INVALID = 'validations:client.ORGANIZATION_TIME_BALANCE_TRACKING_UNIT_IS_INVALID',
  ORGANIZATION_CONTRACT_THRESHOLD_IS_INVALID = 'validations:client.ORGANIZATION_CONTRACT_THRESHOLD_IS_INVALID',
  ORGANIZATION_CONTRACT_DAYS_ARE_INVALID = 'validations:client.ORGANIZATION_CONTRACT_DAYS_ARE_INVALID',
  ORGANIZATION_CONTRACT_UNIT_IS_INVALID = 'validations:client.ORGANIZATION_CONTRACT_UNIT_IS_INVALID',
  ORGANIZATION_CONTRACT_MOBILE_OPTIONS_ARE_INVALID = 'validations:client.ORGANIZATION_CONTRACT_MOBILE_OPTIONS_ARE_INVALID',
  SCHEDULING_SHIFT_STATE_IS_INVALID = 'validations:client.SCHEDULING_SHIFT_STATE_IS_INVALID',
  SCHEDULING_SHIFT_ICON_IS_INVALID = 'validations:client.SCHEDULING_SHIFT_ICON_IS_INVALID',
  SCHEDULING_SHIFT_COLOR_IS_INVALID = 'validations:client.SCHEDULING_SHIFT_COLOR_IS_INVALID',
  SCHEDULING_TIME_TYPE_IS_INVALID = 'validations:client.SCHEDULING_TIME_TYPE_IS_INVALID',
  SCHEDULING_ABSENCE_ICON_IS_INVALID = 'validations:client.SCHEDULING_ABSENCE_ICON_IS_INVALID',
  SCHEDULING_ABSENCE_COLOR_IS_INVALID = 'validations:client.SCHEDULING_ABSENCE_COLOR_IS_INVALID',
  CANT_BE_LESS_THAN_FROM_DATE = 'validations:client.CANT_BE_LESS_THAN_FROM_DATE',
  GREATER_OR_EQUAL_TO_CURRENT_YEAR = 'validations:client.GREATER_OR_EQUAL_TO_CURRENT_YEAR',
  ORGANIZATION_ABSENCE_TYPE_BY_NAME_EXISTS = 'validations.client.ORGANIZATION_ABSENCE_TYPE_BY_NAME_EXISTS',
  ORGANIZATION_POSITION_ASSIGNED_MEMBERS_EXISTS = 'validations.client.ORGANIZATION_POSITION_ASSIGNED_MEMBERS_EXISTS',
  CANT_BE_MORE_THAN_FOUR_DECIMALS = 'validations:client.CANT_BE_MORE_THAN_FOUR_DECIMALS',
  TOTAL_HOURS_CANT_BE_MORE_THAN_WEEK = 'validations:client.TOTAL_HOURS_CANT_BE_MORE_THAN_WEEK',
  INCLUDES = 'validations:client.INCLUDES',
  MIN_DURATION_FROM_NOW = 'validations:client.MIN_DURATION_FROM_NOW',
  MAX_DURATION_FROM_NOW = 'validations:client.MAX_DURATION_FROM_NOW',
  INVALID_DATE_STRING = 'validations:client.INVALID_DATE_STRING',
  INVALID_ONLY_DATE = 'validations:client.INVALID_ONLY_DATE',
  INVALID_ONLY_TIME = 'validations:client.INVALID_ONLY_TIME',
  DEVICE_IS_INVALID = 'validations:client.DEVICE_IS_INVALID',
  CALCULATION_OPERATOR_IS_INVALID = 'validations:client.CALCULATION_OPERATOR_IS_INVALID',
  SHOULD_HAVE_AT_LEAST_ONE_DIGIT = 'validations:client.SHOULD_HAVE_AT_LEAST_ONE_DIGIT',
  SHOULD_HAVE_AT_LEAST_ONE_UPPERCASE_CHAR = 'validations:client.SHOULD_HAVE_AT_LEAST_ONE_UPPERCASE_CHAR',
  SHOULD_HAVE_AT_LEAST_ONE_LOWERCASE_CHAR = 'validations:client.SHOULD_HAVE_AT_LEAST_ONE_LOWERCASE_CHAR',
  NEW_PASS_CANT_BE_SAME_WITH_OLD_ONE = 'validations:client.NEW_PASS_CANT_BE_SAME_WITH_OLD_ONE',
  OVERTIME_HOURS_CANT_BE_LESS_THAN_WEEKLY_TARGET = 'validations:client.OVERTIME_HOURS_CANT_BE_LESS_THAN_WEEKLY_TARGET',
  CANT_BE_AFTER_THAN_DATE = 'validations:client.CANT_BE_AFTER_THAN_DATE',
  CANT_BE_ZERO_OR_LOWER_THAN_ZERO = 'validations:client.CANT_BE_ZERO_OR_LOWER_THAN_ZERO',
  SUM_OF_THE_WEIGHTS_NEEDS_TO_BE_LESS = 'validations:client.SUM_OF_THE_WEIGHTS_NEEDS_TO_BE_LESS',
  SUM_OF_THE_WEIGHTS_NEEDS_TO_BE_HIGHER = 'validations:client.SUM_OF_THE_WEIGHTS_NEEDS_TO_BE_HIGHER',
  ROUND_DOWN_SHOULD_BE_SMALLER_THAN_ROUND_UP = 'validations:client.ROUND_DOWN_SHOULD_BE_SMALLER_THAN_ROUND_UP',
}
